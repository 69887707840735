import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'
import type { CompanyValues } from '@breve/validations/CompanySchema'

import type {
  CommonDeleteResponse,
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
} from './types/common.types'
import type { Company } from './types/company.types'

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/companies`,
  }),
  tagTypes: ['Company'],
  endpoints: builder => ({
    companyList: builder.query<
      CommonPaginationAPIResults<Company>,
      CommonPaginationAPIRequest | void
    >({
      query: params => ({
        url: '/',
        method: 'GET',
        params: params && {
          ...omit('pagination', params),
          ...formatRTKPaginationAPI(params.pagination),
        },
      }),
      providesTags: [{ type: 'Company', id: 'COMPANY_LIST' }],
    }),
    companyDetail: builder.query<Company, number>({
      query: (companyId: number) => ({
        url: `/${companyId}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Company', id: 'DETAILS' }],
    }),
    createCompany: builder.mutation<Company, CompanyValues>({
      query: newCompany => ({
        url: '/',
        method: 'POST',
        data: newCompany,
      }),
      invalidatesTags: [
        { type: 'Company', id: 'COMPANY_LIST' },
        { type: 'Company', id: 'DETAILS' },
      ],
    }),
    updateCompany: builder.mutation<
      Company,
      CommonUpdateRequest<CompanyValues>
    >({
      query: ({
        EntityToUpdate: updatedCompany,
        EntityIdToUpdate: companyToUpdateId,
      }) => ({
        url: `/${companyToUpdateId}/`,
        method: 'PUT',
        data: updatedCompany,
      }),
      invalidatesTags: [
        { type: 'Company', id: 'COMPANY_LIST' },
        { type: 'Company', id: 'DETAILS' },
      ],
    }),
    deleteCompany: builder.mutation<CommonDeleteResponse, number | string>({
      query: companyIdToDelete => ({
        url: `/${companyIdToDelete}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Company', id: 'COMPANY_LIST' },
        { type: 'Company', id: 'DETAILS' },
      ],
    }),
  }),
})

export const {
  useCompanyListQuery,
  useLazyCompanyListQuery,
  useCompanyDetailQuery,
  useLazyCompanyDetailQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = companiesApi
