import dayjs from 'dayjs'
import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '@breve/store/redux/axiosBaseQuery.redux'
import { formatRTKPaginationAPI } from '@breve/utils/mappings.util'
import { omit } from '@breve/utils/omit.util'
import type { PatientRequest, PatientValues } from '@breve/validations'

import type {
  CommonPaginationAPIRequest,
  CommonPaginationAPIResults,
  CommonUpdateRequest,
} from './types/common.types'
import type {
  Patient,
  PatientsQueryParams,
  PatientStatusChangePayload,
  PatientStatusChangeResponse,
} from './types/patient.types'

export const patientsApi = createApi({
  reducerPath: 'patientsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `/patients`,
  }),
  tagTypes: ['Patient'],
  endpoints: builder => ({
    patients: builder.query<
      CommonPaginationAPIResults<Patient>,
      CommonPaginationAPIRequest<PatientsQueryParams> | undefined
    >({
      query: params => {
        return {
          url: '/',
          method: 'GET',
          params: params && {
            ...omit('pagination', params),
            ...formatRTKPaginationAPI(params.pagination),
          },
        }
      },
      providesTags: [{ type: 'Patient', id: 'LIST' }],
    }),
    patientDetail: builder.query<Patient, Patient['id']>({
      query: (patientId: Patient['id']) => ({
        url: `/${patientId}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Patient', id: 'DETAILS' }],
    }),
    createPatient: builder.mutation<Patient, PatientValues>({
      query: newPatient => {
        const newPatientFormatted: PatientRequest = {
          companyProfile: newPatient.company
            ? {
                company: newPatient.company,
                workArea: newPatient?.workArea || '',
                employeeCode: newPatient?.employeeCode || '',
              }
            : null,
          privateProfile: newPatient.doctor
            ? { doctor: newPatient.doctor }
            : null,
          address: newPatient.address,
          birthdate: dayjs(newPatient.birthdate).format('YYYY-MM-DD'),
          firstName: newPatient.firstName,
          firstSurname: newPatient.firstSurname,
          idNumber: newPatient.idNumber,
          sex: newPatient.sex,
          email: newPatient.email,
          phone: newPatient.phone,
          secondName: newPatient.secondName,
          secondSurname: newPatient.secondSurname,
          isYoung: newPatient.isYoung,
        }
        return {
          url: '/',
          method: 'POST',
          data: newPatientFormatted,
        }
      },
      invalidatesTags: [{ type: 'Patient', id: 'LIST' }],
    }),
    updatePatient: builder.mutation<
      Patient,
      CommonUpdateRequest<PatientValues>
    >({
      query: ({
        EntityToUpdate: updatedPatient,
        EntityIdToUpdate: patientId,
      }) => {
        const updatedPatientFormatted: PatientRequest = {
          companyProfile:
            updatedPatient.company &&
            updatedPatient.workArea &&
            updatedPatient.employeeCode
              ? {
                  company: updatedPatient.company,
                  workArea: updatedPatient.workArea,
                  employeeCode: updatedPatient.employeeCode,
                }
              : null,
          privateProfile: updatedPatient.doctor
            ? { doctor: updatedPatient.doctor }
            : null,
          address: updatedPatient.address,
          birthdate: dayjs(updatedPatient.birthdate).format('YYYY-MM-DD'),
          firstName: updatedPatient.firstName,
          firstSurname: updatedPatient.firstSurname,
          idNumber: updatedPatient.idNumber,
          sex: updatedPatient.sex,
          email: updatedPatient.email,
          phone: updatedPatient.phone,
          secondName: updatedPatient.secondName,
          secondSurname: updatedPatient.secondSurname,
          isYoung: updatedPatient.isYoung,
        }
        return {
          url: `/${patientId}/`,
          method: 'PUT',
          data: updatedPatientFormatted,
        }
      },
      invalidatesTags: [{ type: 'Patient', id: 'LIST' }],
    }),
    changePatientStatus: builder.mutation<
      PatientStatusChangeResponse,
      PatientStatusChangePayload
    >({
      query: ({ status: newStatus, id }) => ({
        url: `/${id}/`,
        method: 'PATCH',
        data: { status: newStatus },
      }),
      invalidatesTags: [{ type: 'Patient', id: 'LIST' }],
    }),
  }),
})

export const {
  usePatientsQuery,
  usePatientDetailQuery,
  useCreatePatientMutation,
  useUpdatePatientMutation,
  useChangePatientStatusMutation,
  useLazyPatientsQuery,
  useLazyPatientDetailQuery,
} = patientsApi
